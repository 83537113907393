import React, { useContext } from 'react'
import { isEmpty } from 'lodash'
import { TemplateContext } from '../../context/template'
import ArtistListing from './artistListing'
import ArtistListingPage from './artistListingPage'

const ArtistTemplate = () => {
  const { artistTemplateData, artistTemplateLoaded } = useContext(TemplateContext)

  console.log('artistTemplateData, artistTemplateLoaded', artistTemplateData, artistTemplateLoaded)

  const getPage = (key, data) => {
    if (!key) return
    let pageProps = {
      templateData: artistTemplateData?.template_data,
      componentData: artistTemplateData?.components,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
    }
    let componentObj = {
      artistPage: <ArtistListingPage {...pageProps} />,
    }

    return componentObj[key]
  }

  return (
    <div className="w-full h-full">
      {artistTemplateLoaded ? (
        !isEmpty(artistTemplateData) ? (
          getPage(artistTemplateData?.template_data?.template_key)
        ) : (
          <ArtistListing />
        )
      ) : null}
    </div>
  )
}

export default ArtistTemplate
