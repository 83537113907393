import React from 'react'

const Next1 = ({ color = '#323232', buttonStyles = { width: '14.25px', height: '12px' } }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 14.25 12">
      <path
        data-name="play-next-svgrepo-com (1)"
        d="M2.4 4.085a.75.75 0 0 1 .776.051l7.5 5.25a.75.75 0 0 1 0 1.229l-7.5 5.25A.75.75 0 0 1 2 15.25V4.75a.75.75 0 0 1 .4-.665zM12.5 5.35A1.35 1.35 0 0 1 13.85 4h1.05a1.35 1.35 0 0 1 1.35 1.35v9.3A1.35 1.35 0 0 1 14.9 16h-1.05a1.35 1.35 0 0 1-1.35-1.35v-9.3z"
        transform="translate(-2 -4)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default Next1
