import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import { LOGIN_API, SIGNUP_API } from '../../utils/apiUrls'
import useAxios from 'axios-hooks'
import { raiseToast } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { withTheme } from 'theming'

const Signup = ({ theme }) => {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorData, setErrorData] = useState({})
  const [referralCode, setReferralCode] = useState('')
  const [searchParams] = useSearchParams()
  const code = searchParams.get('referral_code', '')

  const { setLoader, setUser, isCustomTemplate } = useContext(AppContext)
  const navigate = useNavigate()

  const [{ data: loginRes, loading: loginResLoading }, loginCall] = useAxios(
    {
      url: LOGIN_API,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: signUpRes, loading: signUpLoading }, signUpCall] = useAxios(
    {
      url: SIGNUP_API,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (loginRes && loginRes.success) {
      setUser(loginRes?.data, false, true)
      navigate(`/`)
    }
  }, [loginRes])

  useEffect(() => {
    setLoader(loginResLoading || signUpLoading)
  }, [loginResLoading, signUpLoading])

  useEffect(() => {
    if (code) {
      setReferralCode(code)
    }
  }, [code])

  const validateInfo = (type) => {
    let isFormValid = true
    let temp = {}
    if (type === 'register') {
      if (name.trim().length === 0) {
        isFormValid = false
        temp.name = `${t('user_name_empty')}!`
      }
      if (email.trim().length === 0) {
        isFormValid = false
        temp.email = `${t('email_empty')}!`
      }

      if (!email.includes('@') && email.trim().length !== 0) {
        isFormValid = false
        temp.email = `${t('invalid_email')}!`
      }

      if (password !== confirmPassword) {
        isFormValid = false
        temp.confirmPassword = `${t('passwords_match')}!`
      }
      if (password.trim().length === 0) {
        isFormValid = false
        temp.password = `${t('password_empty')}!`
      }
      if (confirmPassword.trim().length === 0) {
        isFormValid = false
        temp.confirmPassword = `${t('password_empty')}!`
      }
    }
    setErrorData({ ...temp })
    return isFormValid
  }

  useEffect(() => {
    if (signUpRes && signUpRes?.success) {
      raiseToast('success', t('register_success_toast'))
      loginCall({
        data: {
          email_id: email,
          password: password,
        },
      })
    }
  }, [signUpRes])

  const handleRegister = async () => {
    if (validateInfo('register')) {
      signUpCall({
        data: {
          email_id: email,
          password: password,
          name,
          phone,
          referral_code: referralCode,
        },
      })
    }
  }

  const handleChange = (event, func) => {
    const { value, name } = event.target

    if (errorData[`${name}`]) {
      delete errorData[`${name}`]
      setErrorData({ ...errorData })
    }
    func(value)
  }

  return (
    <>
      <div
        style={{
          color: isCustomTemplate ? theme.c4 : '#fff',
        }}
        className="flex flex-col items-center"
      >
        <div
          style={{
            backgroundColor: isCustomTemplate ? theme.c10 : '',
            borderColor: isCustomTemplate ? theme.c4 : '',
          }}
          className="w-4/5 md:w-2/4 mt-10 border p-5 rounded-xl"
        >
          <div className="md:text-2xl text-lg font-semibold flex justify-center">
            <h2>{t('register')}</h2>
          </div>
          <div className="relative mt-2 py-6  md:px-20 flex-auto">
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => handleChange(e, setName)}
              style={{
                backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className="border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
              placeholder={`${t('name')} *`}
            />
            {errorData?.name && (
              <div className="error-message">
                <p>{errorData.name}</p>
              </div>
            )}
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => handleChange(e, setEmail)}
              style={{
                backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
              placeholder={`${t('email')} *`}
            />
            {errorData?.email && (
              <div className="error-message">
                <p>{errorData.email}</p>
              </div>
            )}
            <input
              type="number"
              name="phone"
              min="0"
              value={phone}
              onChange={(e) => handleChange(e, setPhone)}
              style={{
                backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
              placeholder={`${t('phone')}`}
            />
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => handleChange(e, setPassword)}
              style={{
                backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
              placeholder={`${t('password')} *`}
            />
            {errorData?.password && (
              <div className="error-message">
                <p>{errorData.password}</p>
              </div>
            )}
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => handleChange(e, setConfirmPassword)}
              style={{
                backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
              placeholder={`${t('confirm_password')} *`}
            />
            {errorData?.confirmPassword && (
              <div className="error-message">
                <p>{errorData.confirmPassword}</p>
              </div>
            )}
            {!code && (
              <input
                type="text"
                name="referralCode"
                value={referralCode}
                onChange={(e) => handleChange(e, setReferralCode)}
                style={{
                  backgroundColor: isCustomTemplate ? theme.c1 : 'rgb(17,24,39)',
                  borderColor: isCustomTemplate ? theme.c5 : 'rgb(75,85,99)',
                  color: isCustomTemplate ? theme.c4 : '#fff',
                }}
                className="mt-6 border lg:text-lg rounded block w-full p-2.5 pl-2 focus:outline-none"
                placeholder={`Referral code`}
              />
            )}
            <div className={'flex justify-center'}>
              <button
                style={{
                  backgroundColor: isCustomTemplate ? theme.c5 : '#1976d2',
                  color: isCustomTemplate ? theme.c7 : '#fff',
                }}
                className="mt-10 ml-2 px-6 py-2 rounded focus:outline-none"
                onClick={handleRegister}
              >
                {t('sign_up')}
              </button>
            </div>
          </div>

          {/*footer*/}
          <div className="flex items-center justify-center  text-sm md:text-lg xl:text-xl">
            <p
              style={{
                color: isCustomTemplate ? theme.c4 : '#fff',
              }}
              className="text-white"
            >
              {t('already_member')}
            </p>
            <p
              style={{ color: isCustomTemplate ? theme.c2 : '#3686d6', cursor: 'pointer' }}
              className="ml-2 hover:underline"
              onClick={() => {
                navigate('/login')
              }}
            >
              {t('sign_in')}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTheme(Signup)
