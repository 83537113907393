import React from 'react'

const Previous3 = ({
  color = '#323232',
  buttonStyles = { width: '11.48px', height: '10.377px' },
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 11.48 10.377">
      <path
        data-name="Path 1229"
        d="M9.434 4.082a1.276 1.276 0 0 1 1.983 1.061v7.821a1.276 1.276 0 0 1-1.983 1.061l-5.866-3.91a1.276 1.276 0 0 1 0-2.123zm.708 1.061L4.276 9.054l5.866 3.911z"
        transform="translate(.063 -3.865)"
        style={{ fillRule: 'evenodd', fill: color }}
      />
      <path
        data-name="Path 1230"
        d="M19 4.638a.638.638 0 1 1 1.276 0v8.929a.638.638 0 1 1-1.276 0z"
        transform="translate(-19 -3.914)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Previous3
