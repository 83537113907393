import React, { useContext, useEffect, useState } from 'react'
import { withTheme } from 'theming'
import BackIcon from '../../assets/back-icon.svg'
import EmailSentIcon from '../../assets/email-sent.svg'
import EmailVerifiedIcon from '../../assets/email-verified.svg'
import EmailConfettiIcon from '../../assets/email-verified-confetti.svg'
import OtpInput from 'react-otp-input'
import { TemplateContext } from '../../context/template'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/app'
import { SEND_VERIFY_EMAIL_OTP, VALIDATE_SESSION, VERIFY_EMAIL_OTP } from '../../utils/apiUrls'
import useAxios from 'axios-hooks'
import { useInterval } from '../../hooks/useInterval'
import { convertCardDuration, raiseToast } from '../../utils/helper'

const VerifyEmailModal = ({ theme }) => {
  const { t } = useTranslation()

  const { verifyOtpModalData, setVerifyOtpModalData, setLoader, setUser } = useContext(AppContext)
  const { signUpTemplateData } = useContext(TemplateContext)
  let SignUpTemplateStyle = signUpTemplateData.data
  let { title = t('verify_your_email'), email = '' } = verifyOtpModalData
  const [otp, setOtp] = useState('')
  const [errorText, setErrorText] = useState('')
  const [timer, setTimer] = useState(120)
  const [isVerified, setIsVerified] = useState(false)

  const [{ data: validateSession, loading: validateSessionLoading }, validateSessionCall] =
    useAxios(
      {
        url: VALIDATE_SESSION,
        method: 'get',
      },
      { manual: true },
    )

  const [{ loading: sendVerifyEmailLoading }, sendVerifyEmailCall] = useAxios(
    {
      url: SEND_VERIFY_EMAIL_OTP,
      method: 'get',
    },
    { manual: true },
  )

  const [{ loading: verifyOtpLoading, error: verifyOtpError }, verifyOtpCall] = useAxios(
    {
      url: VERIFY_EMAIL_OTP,
      method: 'post',
    },
    { manual: true },
  )

  useInterval(
    async () => {
      setTimer((prev) => prev - 1)
    },
    timer ? 1000 : null,
  )

  useEffect(() => {
    if (validateSession && validateSession.success) {
      setUser(validateSession.data, true)
    }
  }, [validateSession])

  useEffect(() => {
    if (email) {
      sendVerifyEmailCall()
    }
  }, [email])

  useEffect(() => {
    if (verifyOtpError) {
      setErrorText(t('you_have_entered_wrong_code_please_try_again'))
    }
  }, [verifyOtpError])

  useEffect(() => {
    setLoader(verifyOtpLoading || sendVerifyEmailLoading || validateSessionLoading)
  }, [verifyOtpLoading, sendVerifyEmailLoading, validateSessionLoading])

  useEffect(() => {
    if (isVerified) {
      setTimeout(() => {
        setVerifyOtpModalData({})
      }, 3000)
    }
  }, [isVerified])

  const validateOtp = () => {
    let isFormValid = true
    let errorString = ''

    if (`${otp}`.trim().length < 6) {
      isFormValid = false
      errorString = `${t('please_enter_valid_otp')}!`
    }

    setErrorText(errorString)
    return isFormValid
  }

  const handleSave = async () => {
    if (!validateOtp()) return
    let otpVerifyRes = await verifyOtpCall({
      data: { otp },
    })
    if (otpVerifyRes?.data?.success) {
      validateSessionCall()
      setIsVerified(true)
    }
  }
  const handleResend = () => {
    if (timer) return

    sendVerifyEmailCall()
    raiseToast('success', t('otp_sent_email'))
    setTimer(120)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!SignUpTemplateStyle?.style_config) {
      return defaultValue ? defaultValue : ''
    }
    let tempArr = SignUpTemplateStyle?.style_config[parentKey]?.data.filter(
      (info) => info.key === childKey,
    )
    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
      style={{ background: '#00000040' }}
    >
      <div className={` relative my-6 w-11/12 md:w-[589px] `}>
        <div
          style={{
            background: getStyleValue('component_background', 'primary_color', theme.c1),
            boxShadow: '3px 3px 6px 0 rgba(0, 0, 0, 0.22)',
            border: 'solid 0.5px #ffffff80',
            color: '#e8e8e8',
          }}
          className={`shadow-lg relative flex flex-col p-5 md:p-7 rounded ${
            isVerified && 'justify-center items-center h-[487px]'
          }`}
        >
          {isVerified ? (
            <>
              <div className="mt-2.5 flex flex-col justify-center items-center">
                <img src={EmailVerifiedIcon} alt="EmailVerifiedIcon" />
                <p className="mt-2.5 font-bold text-[25px]">{t('verified')}!</p>
                <p style={{ marginTop: '22px' }} className="text-sm">
                  {t('congratulations_your_email_is_verified_successfully')}
                </p>
                <img
                  src={EmailConfettiIcon}
                  alt="EmailConfettiIcon"
                  style={{ marginTop: '72px' }}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <img
                  src={BackIcon}
                  alt="BackIcon"
                  onClick={() => {
                    setVerifyOtpModalData({})
                  }}
                  className="cursor-pointer"
                />
              </div>
              <div className="mt-2.5 flex flex-col justify-center items-center">
                <img src={EmailSentIcon} alt="EmailSentIcon" />
                <p className="mt-2.5 font-bold text-[25px]">{title}</p>
                <p className="mt-3 text-sm">{t('please_enter_digit_code_we_sent_to')}</p>
                <p style={{ color: '#e3e3e3' }} className="mt-1 text-sm">
                  {email}
                </p>
                <div style={{ width: '288px' }} className="mt-5 ">
                  <OtpInput
                    value={otp}
                    onChange={(value) => {
                      setOtp(value)
                      setErrorText('')
                    }}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{ width: '38px', height: '45px', fontSize: '26px', color: 'black' }}
                        className="rounded focus:outline-none text-center otp-input"
                      />
                    )}
                    containerStyle={`flex justify-between w-full`}
                    inputType="number"
                    shouldAutoFocus={true}
                  />
                </div>
                {errorText ? (
                  <p style={{ color: '#ff1313' }} className="mt-2.5 text-xs">
                    {errorText}
                  </p>
                ) : (
                  <p className="mt-2.5 text-xs">
                    <span
                      style={{
                        color: timer
                          ? '#ff1313'
                          : getStyleValue('component_background', 'primary_color', theme.c1),
                      }}
                    >
                      {convertCardDuration(timer)}
                    </span>
                  </p>
                )}
                <button
                  style={{
                    backgroundImage: `linear-gradient(to ${getStyleValue(
                      'signup_button',
                      'gradient',
                      'left',
                    )}, ${getStyleValue(
                      'signup_button',
                      'primary_color',
                      theme.c5,
                    )}, ${getStyleValue('signup_button', 'secondary_color', theme.c5)})`,
                    color: getStyleValue('signup_button', 'color', theme.c7),
                    width: '189px',
                    marginTop: '18px',
                  }}
                  className="  py-[7px] rounded text-lg font-medium focus:outline-none"
                  onClick={handleSave}
                >
                  {t('verify')}
                </button>
                <p
                  style={{ marginTop: '52px', visibility: timer ? 'hidden' : 'visible' }}
                  className=" text-xs"
                >
                  {t('didnt_received_the_code')}{' '}
                  <span
                    style={{ color: timer ? '' : '#5560fe' }}
                    className={`${!timer && 'hover:underline cursor-pointer'}  `}
                    onClick={() => {
                      handleResend()
                    }}
                  >
                    {t('resend')}
                  </span>
                </p>
                <p
                  style={{ marginTop: '24px' }}
                  className=" text-sm italic cursor-pointer"
                  onClick={() => {
                    setVerifyOtpModalData({})
                  }}
                >
                  {`${t('skip_for_now')} >>`}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default withTheme(VerifyEmailModal)
