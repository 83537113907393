import React from 'react'

const Backward2 = ({
  color = '#323232',
  buttonStyles = { width: '14.537px', height: '10.081px' },
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 14.537 10.081">
      <path
        data-name="Path 1042"
        d="M9.686 15.627a.888.888 0 0 0 1.443-.694V7.969a.888.888 0 0 0-1.443-.694l-4.353 3.482a.889.889 0 0 0 0 1.388z"
        transform="translate(2.757 -6.411)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
      />
      <path
        data-name="Path 1043"
        d="M17.686 15.627a.888.888 0 0 0 1.443-.694V7.969a.888.888 0 0 0-1.443-.694l-4.353 3.482a.889.889 0 0 0 0 1.388z"
        transform="translate(-12.35 -6.411)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
      />
    </svg>
  )
}

export default Backward2
