import React from 'react'
import PlayPause1 from '../../../assets/customPlayer/playPause/playPause1'
import PlayPause2 from '../../../assets/customPlayer/playPause/playPause2'
import PlayPause3 from '../../../assets/customPlayer/playPause/playPause3'
import PlayPause4 from '../../../assets/customPlayer/playPause/playPause4'
import PlayPause5 from '../../../assets/customPlayer/playPause/playPause5'
import PlayPause6 from '../../../assets/customPlayer/playPause/playPause6'
import PlayPause7 from '../../../assets/customPlayer/playPause/playPause7'

const PlayPauseButton = (props) => {
  let { isPlaying, togglePlayPause, data, iconStyles = { width: '35px', height: '35px' } } = props

  let icons = {
    PLAY1: (
      <>
        <PlayPause1
          isPlaying={isPlaying}
          primaryColor={data.primary_color}
          secondaryColor={data.secondary_color}
        />
      </>
    ),
    PLAY2: (
      <>
        <PlayPause2
          isPlaying={isPlaying}
          primaryColor={data.primary_color}
          secondaryColor={data.secondary_color}
        />
      </>
    ),
    PLAY3: (
      <>
        <PlayPause3
          isPlaying={isPlaying}
          primaryColor={data.primary_color}
          secondaryColor={data.secondary_color}
        />
      </>
    ),
    PLAY4: (
      <>
        <PlayPause4
          isPlaying={isPlaying}
          primaryColor={data.primary_color}
          secondaryColor={data.secondary_color}
        />
      </>
    ),
    PLAY5: (
      <>
        <PlayPause5
          isPlaying={isPlaying}
          primaryColor={data.primary_color}
          secondaryColor={data.secondary_color}
        />
      </>
    ),
    PLAY6: (
      <>
        <PlayPause6
          isPlaying={isPlaying}
          primaryColor={data.primary_color}
          secondaryColor={data.secondary_color}
        />
      </>
    ),
    PLAY7: (
      <>
        <PlayPause7
          isPlaying={isPlaying}
          primaryColor={data.primary_color}
          secondaryColor={data.secondary_color}
        />
      </>
    ),
  }

  return (
    <div style={iconStyles} className="flex justify-center items-center">
      {data.visible ? (
        <div
          className="flex justify-center items-center w-full h-full"
          onClick={() => togglePlayPause(!isPlaying)}
        >
          {icons[data.icon_type]}
        </div>
      ) : null}
    </div>
  )
}

export default PlayPauseButton
