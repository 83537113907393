import React from 'react'

const Previous2 = ({ color = '#323232', buttonStyles = { width: '11.2px', height: '11.2px' } }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 11.2 11.2">
      <path
        data-name="Rectangle 1691"
        style={{
          strokeWidth: '1.1px',
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        d="M0 0h3v10H0z"
        transform="translate(.55 .65)"
      />
      <path
        data-name="Path 1228"
        d="M15.13 9v9.535L9 13.767z"
        transform="translate(-4.58 -8.35)"
        style={{
          strokeWidth: '1.3px',
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  )
}

export default Previous2
