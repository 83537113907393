import React from 'react'
import Forward1 from '../../../assets/customPlayer/forward/forward1'
import Forward2 from '../../../assets/customPlayer/forward/forward2'
import Forward3 from '../../../assets/customPlayer/forward/forward3'
import Forward4 from '../../../assets/customPlayer/forward/forward4'
import Forward5 from '../../../assets/customPlayer/forward/forward5'
import Forward6 from '../../../assets/customPlayer/forward/forward6'
import Forward7 from '../../../assets/customPlayer/forward/forward7'

const ForwardButton = (props) => {
  let {
    forwardClick,
    data,
    buttonStyles,
    iconStyles = { width: '41px', height: '26px', background: `${data.secondary_color}60` },
  } = props

  let icons = {
    BACKWARD1: (
      <>
        <Forward1 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD2: (
      <>
        <Forward2 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD3: (
      <>
        <Forward3 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD4: (
      <>
        <Forward4 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD5: (
      <>
        <Forward5 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD6: (
      <>
        <Forward6 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD7: (
      <>
        <Forward7 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
  }

  return (
    <>
      {data.visible ? (
        <div
          style={iconStyles}
          className="flex justify-center items-center rounded-full cursor-pointer"
          onClick={forwardClick}
        >
          <div className="">{icons[data.icon_type]}</div>
        </div>
      ) : null}
    </>
  )
}

export default ForwardButton
