import React from 'react'

const Next5 = ({ color = '#000', buttonStyles = { width: '18.581px', height: '18.581px' } }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 18.582 18.582">
      <path
        data-name="Path 1231"
        d="M29.531 29.344a8.641 8.641 0 1 1 0 12.22 8.613 8.613 0 0 1 0-12.22z"
        transform="translate(-26.35 -26.163)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
          strokeWidth: '1.3px',
        }}
      />
      <g data-name="Group 1261">
        <path
          data-name="Path 1232"
          d="m51.63 39.936 3.795 3.795-3.795 3.794z"
          transform="translate(-41.802 -34.396)"
          style={{
            fill: 'none',
            stroke: color,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeWidth: '1.3px',
          }}
        />
        <path
          data-name="Line 273"
          transform="translate(6.621 5.564)"
          style={{
            fill: 'none',
            stroke: color,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeWidth: '1.3px',
          }}
          d="M0 0v7.453"
        />
      </g>
    </svg>
  )
}

export default Next5
