import React, { useState } from 'react'
import Volume1 from '../../../assets/customPlayer/volume/volume1'
// import Volume2Left from '../../../assets/customPlayer/volume/volume2Left'
// import Volume2Right from '../../../assets/customPlayer/volume/volume2Right'
import Volume3 from '../../../assets/customPlayer/volume/volume3'

const VolumeBarType2 = (props) => {
  let { toggleMuted, volume, toggleVolume, isMute, playerData } = props

  const [showVolBar, setShowVolBar] = useState(false)

  let data = playerData.control_bar.data.left_control_buttons.volume

  let icons = {
    VOLUME1: (
      <div className="h-full">
        <Volume1
          color={data.icon_color}
          clickFunc={() => toggleMuted(!isMute)}
          volume={volume}
          isMute={isMute}
        />
      </div>
    ),
    VOLUME2: (
      <div className="h-full">
        <Volume1
          color={data.icon_color}
          clickFunc={() => toggleMuted(!isMute)}
          volume={volume}
          isMute={isMute}
        />
      </div>
    ),
    VOLUME3: (
      <div className="h-full">
        <Volume3
          color={data.icon_color}
          clickFunc={() => toggleMuted(!isMute)}
          volume={volume}
          isMute={isMute}
        />
      </div>
    ),
  }

  //   const stepVolumeChange = (value) => {
  //     if (value < 0 || value > 100) return
  //     toggleVolume(value)
  //   }

  return (
    <div
      className="h-full"
      style={{ width: showVolBar ? '36px' : 'auto', transition: 'width 0.25s ease-in-out' }}
      onMouseOver={() => setShowVolBar(true)}
      onMouseLeave={() => setShowVolBar(false)}
    >
      {data.visible ? (
        <div className="flex justify-start items-center w-full h-full rounded-full">
          <>
            {icons[data.icon_type]}
            <div
              style={{
                width: showVolBar ? '106px' : '0px',
                background: '#000',
                marginLeft: '36px',
                height: '32px',
                // visibility: showVolBar ? 'visible' : 'hidden',
                transition: 'width 0.25s ease-in-out',
              }}
              className="flex items-center justify-center absolute left-0 rounded-md"
            >
              <input
                type="range"
                className={`volume-range-bar overflow-hidden`}
                min={0}
                max={100}
                step={2}
                value={volume}
                onChange={(event) => {
                  toggleVolume(event.target.valueAsNumber)
                }}
                style={{
                  width: `85%`,
                  height: '4px',
                  accentColor: data.active_color,
                }}
              />
            </div>
          </>
        </div>
      ) : null}
    </div>
  )
}

export default VolumeBarType2
