import React from 'react'

const Next3 = ({ color = '#323232', buttonStyles = { width: '11.48px', height: '10.377px' } }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 11.48 10.377">
      <path
        data-name="Path 1229"
        d="M4.983 4.082A1.276 1.276 0 0 0 3 5.143v7.821a1.276 1.276 0 0 0 1.983 1.061l5.866-3.911a1.276 1.276 0 0 0 0-2.123zm-.707 1.061 5.866 3.911-5.866 3.91z"
        style={{ fillRule: 'evenodd', fill: color }}
        transform="translate(-3 -3.865)"
      />
      <path
        data-name="Path 1230"
        d="M20.276 4.638a.638.638 0 0 0-1.276 0v8.929a.638.638 0 1 0 1.276 0z"
        transform="translate(-8.795 -3.914)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Next3
