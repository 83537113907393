import React, { useEffect, useState } from 'react'
import CustomImage from '../../components/customImage/customImage'
import useTemplateHook from '../../components/template/templateHook'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import PassimPlayer from '../../components/template/rosemary/passimPlayer'
import { useSearchParams } from 'react-router-dom'

const CrescendoSignUp = (props) => {
  let { isEdit = false, theme, data, setModalState } = props

  console.log('props', data)
  const { t } = useTranslation()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [searchParams] = useSearchParams()
  const code = searchParams.get('referral_code', '')

  const [errorData, setErrorData] = useState({})

  const [hoverState, setHoverState] = useState('')
  // const {} = useContext(AppContext)
  const {
    customNavigate,
    getTemplateImage,
    setLoader,
    setUser,
    LOGIN_API,
    SIGNUP_API,
    EditIcon,
    raiseToast,
  } = useTemplateHook()

  const [{ data: loginRes, loading: loginResLoading }, loginCall] = useAxios(
    {
      url: LOGIN_API,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: signUpRes, loading: signUpLoading }, signUpCall] = useAxios(
    {
      url: SIGNUP_API,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (loginRes && loginRes.success) {
      setUser(loginRes?.data, false, true)
      customNavigate('DIRECT', '/')
    }
  }, [loginRes])

  useEffect(() => {
    if (signUpRes && signUpRes?.success) {
      raiseToast('success', t('register_success_toast'))
      loginCall({
        data: {
          email_id: email,
          password: password,
        },
      })
    }
  }, [signUpRes])

  useEffect(() => {
    setLoader(loginResLoading || signUpLoading)
  }, [loginResLoading, signUpLoading])

  useEffect(() => {
    if (code) {
      setReferralCode(code)
    }
  }, [code])

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const validateInfo = (type) => {
    let isFormValid = true
    let temp = {}

    if (name.trim().length === 0) {
      isFormValid = false
      temp.name = `${t('user_name_empty')}!`
    }
    if (email.trim().length === 0) {
      isFormValid = false
      temp.email = `${t('email_empty')}!`
    }

    if (!email.includes('@') && email.trim().length !== 0) {
      isFormValid = false
      temp.email = `${t('invalid_email')}!`
    }

    if (password !== confirmPassword) {
      isFormValid = false
      temp.confirmPassword = `${t('passwords_match')}!`
    }
    if (password.trim().length === 0) {
      isFormValid = false
      temp.password = `${t('password_empty')}!`
    }
    if (confirmPassword.trim().length === 0) {
      isFormValid = false
      temp.confirmPassword = `${t('password_empty')}!`
    }

    setErrorData({ ...temp })
    return isFormValid
  }

  const handleRegister = async () => {
    if (validateInfo()) {
      signUpCall({
        data: {
          email_id: email,
          password: password,
          name,
          phone,
          referral_code: referralCode,
        },
      })
    }
  }

  const handleChange = (event, func) => {
    const { value, name } = event.target

    if (errorData[`${name}`]) {
      delete errorData[`${name}`]
      setErrorData({ ...errorData })
    }
    func(value)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const handleModal = (data) => {
    setModalState({ ...data })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }
  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative`}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <div
          className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-4 right-4 z-10 flex justify-center items-center cursor-pointer"
          onClick={() => handleModal({ modalKey: 'sceneCenterSignInModal', data: data })}
        >
          <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
        </div>
      )}
      <div
        className={`w-full h-full flex ${
          data?.aligned === 'LEFT' ? 'flex-row' : 'flex-row-reverse'
        } justify-center xl:justify-between `}
        style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
      >
        <div className="w-[80%] xl:w-1/2 flex justify-center items-center">
          <div className=" xl:w-[77.8125%] xl:ml-[4.4270%] xl:px-[14.959%] flex flex-col items-center">
            <div
              className={` w-full flex justify-center ${
                isEdit && hoverState === 'IMAGE' && 'component-hover-class'
              } xl:px-[50px] relative`}
              onMouseOver={() => handleHoverItems('IMAGE')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'IMAGE' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'logoModal',
                      data: data,
                      modalSpecificData: {
                        imgWidth: 255,
                        imgHeight: 70,
                        divWidth: '25.5rem',
                        divHeight: '7rem',
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <CustomImage
                imageUrl={getTemplateImage(data?.logo)}
                imgClass="w-[146px] lg:w-[215px]"
                loaderClass=""
                imgAlt={'image_s3key'}
              />
            </div>
            <div
              className={`w-full ${
                isEdit && hoverState === 'SIGNUP_TEXT' && 'component-hover-class'
              }  mt-8 relative`}
              onMouseOver={() => handleHoverItems('SIGNUP_TEXT')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'SIGNUP_TEXT' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'signupTextModal',
                      data: data,
                      modalSpecificData: {
                        stylesConfigArr: ['signup_heading', 'signup_description'],
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <p
                style={{
                  color: getStyleValue('signup_heading', 'color', theme.c5),
                  fontFamily: getStyleValue('signup_heading', 'font', ''),
                  textAlign: getStyleValue('signup_heading', 'alignment', ''),
                }}
                className={` mb-0 text-xl lg:text-[25px] font-bold ${getStyleValue(
                  'signup_heading',
                  'font_format',
                  '',
                )}`}
              >
                {data.signup_text}
              </p>
              <p
                style={{
                  color: getStyleValue('signup_description', 'color', theme.c3),
                  fontFamily: getStyleValue('signup_description', 'font', ''),
                  textAlign: getStyleValue('signup_description', 'alignment', ''),
                }}
                className={` mb-0 mt-[12px] text-xs ${getStyleValue(
                  'signup_description',
                  'font_format',
                  '',
                )}`}
              >
                {data.signup_description}
              </p>
            </div>
            <div
              className={`w-full ${
                isEdit && hoverState === 'INPUT' && 'component-hover-class'
              }  mt-10 relative`}
              onMouseOver={() => handleHoverItems('INPUT')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'INPUT' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'signupInputModal',
                      data: data,
                      modalSpecificData: {
                        stylesConfigArr: ['email_password_text', 'email_password_input'],
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <>
                <p
                  style={{
                    color: getStyleValue('email_password_text', 'color', theme.c4),
                    fontFamily: getStyleValue('email_password_text', 'font', ''),
                    textAlign: getStyleValue('email_password_text', 'alignment', ''),
                  }}
                  className={`${getStyleValue(
                    'email_password_text',
                    'font_format',
                    '',
                  )} mb-0  text-sm lg:text-base`}
                >
                  {data?.name_text} <span className="text-red-600">*</span>
                </p>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => handleChange(e, setName)}
                  style={{
                    background: getStyleValue(
                      'email_password_input',
                      'background_color',
                      'transparent',
                    ),
                    color: getStyleValue('email_password_input', 'text_color', theme.c4),
                    border: `0.5px solid ${getStyleValue(
                      'email_password_input',
                      'border_color',
                      theme.c4,
                    )}`,
                  }}
                  className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none "
                />
                {errorData?.name && (
                  <div className="error-message">
                    <p>{errorData.name}</p>
                  </div>
                )}
              </>

              <p
                style={{
                  color: getStyleValue('email_password_text', 'color', theme.c4),
                  fontFamily: getStyleValue('email_password_text', 'font', ''),
                  textAlign: getStyleValue('email_password_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'email_password_text',
                  'font_format',
                  '',
                )} mb-0 mt-[16px] text-sm lg:text-base`}
              >
                {data?.email_text}
                <span className="text-red-600 ml-1">*</span>
              </p>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => handleChange(e, setEmail)}
                style={{
                  background: getStyleValue(
                    'email_password_input',
                    'background_color',
                    'transparent',
                  ),
                  color: getStyleValue('email_password_input', 'text_color', theme.c4),
                  border: `0.5px solid ${getStyleValue(
                    'email_password_input',
                    'border_color',
                    theme.c5,
                  )}`,
                }}
                className=" border text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none"
              />
              {errorData?.email && (
                <div className="error-message">
                  <p>{errorData.email}</p>
                </div>
              )}

              <p
                style={{
                  color: getStyleValue('email_password_text', 'color', theme.c4),
                  fontFamily: getStyleValue('email_password_text', 'font', ''),
                  textAlign: getStyleValue('email_password_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'email_password_text',
                  'font_format',
                  '',
                )} mb-0 mt-[16px] text-sm lg:text-base`}
              >
                {data?.phone}
              </p>
              <input
                type="number"
                name="phone"
                value={phone}
                onChange={(e) => handleChange(e, setPhone)}
                style={{
                  background: getStyleValue(
                    'email_password_input',
                    'background_color',
                    'transparent',
                  ),
                  color: getStyleValue('email_password_input', 'text_color', theme.c4),
                  border: `0.5px solid ${getStyleValue(
                    'email_password_input',
                    'border_color',
                    theme.c4,
                  )}`,
                }}
                className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none "
              />

              <p
                style={{
                  color: getStyleValue('email_password_text', 'color', theme.c4),
                  fontFamily: getStyleValue('email_password_text', 'font', ''),
                  textAlign: getStyleValue('email_password_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'email_password_text',
                  'font_format',
                  '',
                )} mb-0 mt-[16px]  text-sm lg:text-base`}
              >
                {data.password_text} <span className="text-red-600 ml-1">*</span>
              </p>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => handleChange(e, setPassword)}
                style={{
                  background: getStyleValue(
                    'email_password_input',
                    'background_color',
                    'transparent',
                  ),
                  color: getStyleValue('email_password_input', 'text_color', theme.c4),
                  border: `0.5px solid ${getStyleValue(
                    'email_password_input',
                    'border_color',
                    theme.c4,
                  )}`,
                }}
                className=" border text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none"
              />
              {errorData?.password && (
                <div className="error-message">
                  <p>{errorData.password}</p>
                </div>
              )}

              <p
                style={{
                  color: getStyleValue('email_password_text', 'color', theme.c4),
                  fontFamily: getStyleValue('email_password_text', 'font', ''),
                  textAlign: getStyleValue('email_password_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'email_password_text',
                  'font_format',
                  '',
                )} mb-0 mt-[16px] text-sm lg:text-base`}
              >
                {data?.confirm_password_text} <span className="text-red-600 ml-1">*</span>
              </p>
              <input
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => handleChange(e, setConfirmPassword)}
                style={{
                  background: getStyleValue(
                    'email_password_input',
                    'background_color',
                    'transparent',
                  ),
                  color: getStyleValue('email_password_input', 'text_color', theme.c4),
                  border: `0.5px solid ${getStyleValue(
                    'email_password_input',
                    'border_color',
                    theme.c4,
                  )}`,
                }}
                className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none"
              />
              {errorData?.confirmPassword && (
                <div className="error-message">
                  <p>{errorData.confirmPassword}</p>
                </div>
              )}

              <p
                style={{
                  color: getStyleValue('email_password_text', 'color', theme.c4),
                  fontFamily: getStyleValue('email_password_text', 'font', ''),
                  textAlign: getStyleValue('email_password_text', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'email_password_text',
                  'font_format',
                  '',
                )} mb-0 mt-[16px] text-sm lg:text-base`}
              >
                {data?.referral_code_text}
              </p>
              <input
                type="text"
                name="referralCode"
                value={referralCode}
                onChange={(e) => handleChange(e, setReferralCode)}
                style={{
                  background: getStyleValue(
                    'email_password_input',
                    'background_color',
                    'transparent',
                  ),
                  color: getStyleValue('email_password_input', 'text_color', theme.c4),
                  border: `0.5px solid ${getStyleValue(
                    'email_password_input',
                    'border_color',
                    theme.c4,
                  )}`,
                }}
                className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none "
              />
            </div>

            <div
              className={`mt-10 w-full text-center ${
                isEdit && hoverState === 'BUTTON' && 'component-hover-class'
              } relative`}
              onMouseOver={() => handleHoverItems('BUTTON')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'BUTTON' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'signupButtonModal',
                      data: data,
                      modalSpecificData: {
                        stylesConfigArr: ['signup_button', 'account_text'],
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <button
                style={{
                  backgroundImage: `linear-gradient(to ${getStyleValue(
                    'signup_button',
                    'gradient',
                    'left',
                  )}, ${getStyleValue('signup_button', 'primary_color', theme.c5)}, ${getStyleValue(
                    'signup_button',
                    'secondary_color',
                    theme.c5,
                  )})`,
                  color: getStyleValue('signup_button', 'text_color', theme.c1),
                }}
                className="w-full  py-2 rounded xl:text-lg font-medium focus:outline-none"
                onClick={handleRegister}
              >
                {data.signup_button_text}
              </button>
              <p
                className={`mb-0 mt-8 text-sm lg:text-base ${getStyleValue(
                  'account_text',
                  'font_format',
                  '',
                )}`}
                style={{
                  color: getStyleValue('account_text', 'color', theme.c3),
                  fontFamily: getStyleValue('account_text', 'font', ''),
                  textAlign: getStyleValue('account_text', 'alignment', ''),
                }}
              >
                {data.account_text}{' '}
                <span
                  className="font-semibold cursor-pointer"
                  style={{ color: getStyleValue('account_text', 'register_text_color', theme.c5) }}
                  onClick={() => {
                    customNavigate('DIRECT', '/login')
                  }}
                >
                  {data.signin_text}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="hidden xl:block xl:w-1/2">
          {isEdit || !data.banner_data?.player?.type ? (
            <CustomImage
              imageUrl={getTemplateImage(data.banner_data?.image_s3_key)}
              imgClass="w-full h-full"
              loaderClass="w-full h-full"
              loaderStyle={{
                background: '#5A5A5A50',
              }}
              imgStyle={{ aspectRatio: '960/1080' }}
              imgAlt={'banner_data'}
              onClickRedirect={() => handleRedirect(data.banner_data?.link)}
            />
          ) : (
            <div className="w-full h-full">
              <PassimPlayer
                data={data.banner_data?.player}
                poster={getTemplateImage(data.banner_data?.image_s3key)}
                controlBar={false}
                isBanner={true}
                onClickRedirect={() => handleRedirect(data.banner_data.link)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withTheme(CrescendoSignUp)
