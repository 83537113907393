import React from 'react'

const Forward5 = ({ color = '#323232', buttonStyles = { width: '27px', height: '27px' } }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 27 27">
      <g data-name="Group 1142">
        <path
          data-name="Path 1026"
          d="M19.152 20.8a2.365 2.365 0 0 1-1.066-.225 2.032 2.032 0 0 1-.741-.641 2.973 2.973 0 0 1-.433-1.041 6.4 6.4 0 0 1-.142-1.4 6.521 6.521 0 0 1 .142-1.4 3.04 3.04 0 0 1 .433-1.041 2.032 2.032 0 0 1 .741-.641 2.365 2.365 0 0 1 1.066-.225 2.032 2.032 0 0 1 1.8.833 4.356 4.356 0 0 1 .583 2.44 4.356 4.356 0 0 1-.583 2.44 2.032 2.032 0 0 1-1.8.901zm0-1.016a.891.891 0 0 0 .833-.458 2.815 2.815 0 0 0 .308-1.258V16.92a2.757 2.757 0 0 0-.242-1.249 1.024 1.024 0 0 0-1.716 0 2.757 2.757 0 0 0-.242 1.249v1.15a2.815 2.815 0 0 0 .242 1.258.883.883 0 0 0 .817.457z"
          transform="translate(-2.804 -2.28)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1027"
          d="M10.561 20.708v-.983h1.666V15.4l-1.549.833-.458-.883 1.932-1.083h1.216v5.413h1.482v1.024z"
          transform="translate(-1.709 -2.294)"
          style={{ fill: color }}
        />
        <path data-name="<Transparent Rectangle>" style={{ fill: 'none' }} d="M0 0h27v27H0z" />
        <g data-name="rewind-10-svgrepo-com">
          <path
            data-name="Path 1025"
            d="M23.987 14.951a9.994 9.994 0 1 1-9.994-9.994h3.331V2.27l5 3.521-5 3.313V6.623h-3.33a8.328 8.328 0 1 0 8.328 8.328z"
            transform="translate(-.318 .131)"
            style={{ fill: color }}
          />
          <path data-name="<Transparent Rectangle>" style={{ fill: 'none' }} d="M0 0h27v27H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Forward5
