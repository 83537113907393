import React from 'react'

const Forward1 = ({ color = '#000', buttonStyles = { width: '16.397px', height: '9.78px' } }) => {
  return (
    <svg
      data-name="Group 1092"
      xmlns="http://www.w3.org/2000/svg"
      style={buttonStyles}
      viewBox="0 0 16.397 9.78"
    >
      <path
        data-name="play-svgrepo-com (2)"
        d="m5.691 3.627 7.869 4.427a.464.464 0 0 1 0 .808l-7.869 4.427a.464.464 0 0 1-.691-.4V4.032a.464.464 0 0 1 .691-.4z"
        transform="translate(-5 -3.568)"
        style={{ fill: color }}
      />
      <path
        data-name="play-svgrepo-com (2)"
        d="m5.691 3.627 7.869 4.427a.464.464 0 0 1 0 .808l-7.869 4.427a.464.464 0 0 1-.691-.4V4.032a.464.464 0 0 1 .691-.4z"
        transform="translate(2.601 -3.568)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Forward1
