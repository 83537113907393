import React from 'react'
import Next1 from '../../../assets/customPlayer/next/next1'
import Next2 from '../../../assets/customPlayer/next/next2'
import Next3 from '../../../assets/customPlayer/next/next3'
import Next4 from '../../../assets/customPlayer/next/next4'
import Next5 from '../../../assets/customPlayer/next/next5'

const NextButton = (props) => {
  let {
    data,
    buttonStyles,
    iconStyles = { width: '39px', height: '26px', background: `${data.secondary_color}60` },
  } = props

  let icons = {
    PREVNEXT1: (
      <>
        <Next1 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    PREVNEXT2: (
      <>
        <Next2 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    PREVNEXT3: (
      <>
        <Next3 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    PREVNEXT4: (
      <>
        <Next4 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    PREVNEXT5: (
      <>
        <Next5 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
  }
  return (
    <>
      {data.visible ? (
        <div
          style={iconStyles}
          className="flex justify-center items-center rounded-full cursor-pointer"
        >
          <div className="">{icons[data.icon_type]}</div>
        </div>
      ) : null}
    </>
  )
}

export default NextButton
