import React, { useContext } from 'react'
import { isEmpty } from 'lodash'
import { TemplateContext } from '../../context/template'
import VenueListing from './venueListing'
import VenueListingPage from './venueListingPage'

const VenueTemplate = () => {
  const { venueTemplateData, venueTemplateLoaded } = useContext(TemplateContext)

  const getPage = (key, data) => {
    if (!key) return
    let pageProps = {
      templateData: venueTemplateData?.template_data,
      componentData: venueTemplateData?.components,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
    }
    let componentObj = {
      venuePage: <VenueListingPage {...pageProps} />,
    }

    return componentObj[key]
  }

  return (
    <div className="w-full h-full">
      {venueTemplateLoaded ? (
        !isEmpty(venueTemplateData) ? (
          getPage(venueTemplateData?.template_data?.template_key)
        ) : (
          <VenueListing />
        )
      ) : null}
    </div>
  )
}

export default VenueTemplate
