import React from 'react'

const Forward7 = ({
  color = '#323232',
  buttonStyles = { width: '20.533px', height: '20.533px' },
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 20.533 20.533">
      <path
        d="M1.25 11.517A10.267 10.267 0 0 1 11.517 1.25a.716.716 0 0 1 .559 1.164L10.166 4.8a.716.716 0 0 1-1.119-.895l.863-1.077a8.832 8.832 0 1 0 5.14.589.716.716 0 1 1 .574-1.317A10.268 10.268 0 1 1 1.25 11.517zm8.667-3.989a.716.716 0 0 1 .406.646v6.685a.716.716 0 0 1-1.433 0V9.664l-1.224.979a.716.716 0 0 1-.895-1.119l2.388-1.91a.716.716 0 0 1 .758-.086zm3.749 1.362a.955.955 0 0 0-.955.955v3.343a.955.955 0 0 0 1.91 0V9.845a.955.955 0 0 0-.955-.955zm-2.388.955a2.388 2.388 0 0 1 4.775 0v3.343a2.388 2.388 0 0 1-4.775 0z"
        transform="translate(-1.25 -1.25)"
        style={{ fill: color, fillRule: 'evenodd' }}
      />
    </svg>
  )
}

export default Forward7
