import React from 'react'
import Backward1 from '../../../assets/customPlayer/backward/backward1'
import Backward2 from '../../../assets/customPlayer/backward/backward2'
import Backward3 from '../../../assets/customPlayer/backward/backward3'
import Backward4 from '../../../assets/customPlayer/backward/backward4'
import Backward5 from '../../../assets/customPlayer/backward/backward5'
import Backward6 from '../../../assets/customPlayer/backward/backward6'
import Backward7 from '../../../assets/customPlayer/backward/backward7'

const BackwardButton = (props) => {
  let {
    backwardClick,
    data,
    buttonStyles,
    iconStyles = { width: '41px', height: '26px', background: `${data.secondary_color}60` },
  } = props

  let icons = {
    BACKWARD1: (
      <>
        <Backward1 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD2: (
      <>
        <Backward2 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD3: (
      <>
        <Backward3 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD4: (
      <>
        <Backward4 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD5: (
      <>
        <Backward5 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD6: (
      <>
        <Backward6 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    BACKWARD7: (
      <>
        <Backward7 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
  }
  return (
    <>
      {data.visible ? (
        <div
          style={iconStyles}
          className="flex justify-center items-center rounded-full cursor-pointer"
          onClick={backwardClick}
        >
          <div>{icons[data.icon_type]}</div>
        </div>
      ) : null}
    </>
  )
}

export default BackwardButton
