import React from 'react'
import Previous1 from '../../../assets/customPlayer/previous/previous1'
import Previous2 from '../../../assets/customPlayer/previous/previous2'
import Previous3 from '../../../assets/customPlayer/previous/previous3'
import Previous4 from '../../../assets/customPlayer/previous/previous4'
import Previous5 from '../../../assets/customPlayer/previous/previous5'

const PrevButton = (props) => {
  let {
    data,
    buttonStyles,
    iconStyles = { width: '39px', height: '26px', background: `${data.secondary_color}60` },
  } = props

  let icons = {
    PREVNEXT1: (
      <>
        <Previous1 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    PREVNEXT2: (
      <>
        <Previous2 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    PREVNEXT3: (
      <>
        <Previous3 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    PREVNEXT4: (
      <>
        <Previous4 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
    PREVNEXT5: (
      <>
        <Previous5 color={data.primary_color} buttonStyles={buttonStyles} />
      </>
    ),
  }
  return (
    <>
      {data.visible ? (
        <div
          style={iconStyles}
          className="flex justify-center items-center rounded-full cursor-pointer"
        >
          <div className="">{icons[data.icon_type]}</div>
        </div>
      ) : null}
    </>
  )
}

export default PrevButton
