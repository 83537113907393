import React, { useEffect, useState } from 'react'
import { convertVideoSecondstoMintues } from './playerHelper'
import { isEmpty } from 'lodash'
import ControlBarType1 from './controlBars/controlBarType1'
import ControlBarType2 from './controlBars/controlBarType2'

const ControlBarHome = (props) => {
  let {
    playerEle,
    playerData,
    showControlBar,
    setCursorOverControlBar,
    duration,
    currentTime,
    bufferPercent,
    volume,
    isPlaying,
    togglePlayPause,
    forwardClick,
    backwardClick,
    handleNext,
    handlePrev,
    isPIPMode,
    isFullScreen,
    showSettingMenu,
    setShowSettingMenu,
  } = props
  const [controlBarData, setControlBarData] = useState({})
  const [seekBarHoverPercent, setSeekBarHoverPercent] = useState(0)
  const [isMute, setIsMute] = useState(false)

  useEffect(() => {
    if (!isEmpty(playerData)) {
      setControlBarData(playerData?.control_bar)
    }
  }, [playerData])

  const handleSeek = (event) => {
    if (!playerEle) {
      return
    }
    let sec =
      (event.nativeEvent.offsetX / event.target.parentNode.clientWidth) * playerEle.duration()

    playerEle.currentTime(sec)
  }

  const handleSeekOver = (event) => {
    if (!playerEle) {
      return
    }
    setSeekBarHoverPercent(event.nativeEvent.offsetX / event.target.parentNode.clientWidth)
  }

  const toggleFullScreen = (isFull) => {
    if (!playerEle) {
      return
    }
    isFull ? playerEle.requestFullscreen() : playerEle.exitFullscreen()
  }

  const togglePIPMode = (isPIP) => {
    if (!playerEle) {
      return
    }
    isPIP ? playerEle.requestPictureInPicture() : playerEle.exitPictureInPicture()
  }

  const toggleVolume = (value) => {
    if (!playerEle) {
      return
    }
    setIsMute(false)
    playerEle.muted(false)
    playerEle.volume(value / 100)
  }

  const toggleMuted = (status) => {
    if (!playerEle) {
      return
    }
    playerEle.volume(status ? 0 : 100)
    playerEle.muted(status)
    setIsMute(status)
  }

  const getControlBar = () => {
    let barProps = {
      playerData,
      controlBarData,
      playerEle,
      showControlBar,
      setCursorOverControlBar,
      duration,
      currentTime,
      bufferPercent,
      volume,
      isPlaying,
      togglePlayPause,
      forwardClick,
      backwardClick,
      handleNext,
      handlePrev,
      isPIPMode,
      isFullScreen,
      showSettingMenu,
      setShowSettingMenu,
      handleSeek,
      handleSeekOver,
      toggleFullScreen,
      togglePIPMode,
      toggleVolume,
      toggleMuted,
      seekBarHoverPercent,
      isMute,
      convertVideoSecondstoMintues,
    }
    let barObj = {
      type1: <ControlBarType1 {...barProps} />,
      type2: <ControlBarType2 {...barProps} />,
    }

    return barObj[controlBarData.key]
  }

  return <>{!isEmpty(controlBarData) ? getControlBar() : null}</>
}

export default ControlBarHome
