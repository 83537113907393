import React, { useEffect, useState } from 'react'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import useAxios from 'axios-hooks'
import useTemplateHook from '../../components/template/templateHook'
import VenueCard from '../../components/contentCard/venueCard'
import LoaderTile from '../../components/contentCard/loaderTile'

let totalRecords = 0
let currentPage = 1
let apiHit = false

const VenuePageComponent = ({ data, setModalState, isEdit = false, theme }) => {
  const [hoverState, setHoverState] = useState('')
  const { VENUE_LISTING, EditIcon, setLoader, PAGE_SIZE } = useTemplateHook()
  const [dataList, setDataList] = useState([])
  const language = localStorage.getItem('language') || 'en'

  const [{ data: venueList, loading: venueListLoading }, venueListCall] = useAxios(
    {
      url: VENUE_LISTING,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (venueList && venueList.success) {
      if (data.type === 'STATIC') {
        venueList?.data.sort((a, b) => {
          let sortingArr = data.venue_data
          return sortingArr.indexOf(a._id) - sortingArr.indexOf(b._id)
        })
      }
      if (isEdit) {
        setDataList([...venueList.data])
        return
      }

      setDataList((dataList) => [...dataList, ...venueList.data])
      totalRecords = venueList.total
      apiHit = false
    }
  }, [venueList])

  useEffect(() => {
    setLoader(venueListLoading)
  }, [venueListLoading])

  useEffect(() => {
    if (!isEmpty(data.venue_data) || !isEmpty(data.auto_payload)) {
      hitAPI(1)
    } else {
      setDataList([])
    }
  }, [data])

  const hitAPI = (page) => {
    if (isEmpty(data)) return

    let filterPayload =
      data.type === 'STATIC'
        ? { venue_ids: data.venue_data }
        : {
            // system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
            custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
          }

    let filters = {
      filters: filterPayload,
      page: page,
      order_by: data?.order_by === 'name' ? `name.${language}` : data?.order_by,
      reverse: data?.reverse,
      page_size: PAGE_SIZE,
    }

    if (data.type === 'STATIC') {
      delete filters.order_by
      delete filters.reverse
    }

    apiHit = true
    venueListCall({
      data: filters,
    })
  }

  const handleHoverItems = (name) => {
    // if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState(data)
  }

  useEffect(() => {
    window.addEventListener('scroll', infiniteScroll)
    return function cleanupListener() {
      window.removeEventListener('scroll', infiniteScroll)
    }
  }, [])

  const infiniteScroll = () => {
    // End of the document reached?
    if (
      window.innerHeight + document.documentElement.scrollTop >
      0.8 * document.documentElement.offsetHeight
    ) {
      if (!apiHit && totalRecords > currentPage * PAGE_SIZE) {
        apiHit = true
        currentPage += 1

        hitAPI(currentPage)
      }
    }
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative py-6 lg:py-10`}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
      }}
    >
      {isEdit && hoverState.length > 0 && (
        <div
          className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-4 right-4 z-10 flex justify-center items-center cursor-pointer"
          onClick={() => handleModal()}
        >
          <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
        </div>
      )}

      <div className="w-full">
        <div className=" w-full flex flex-col items-center justify-center px-10 md:px-16 lg:px-20">
          <div className="w-full flex justify-center z-40 relative">
            <div
              style={{
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                background: `${getStyleValue('heading', 'background_color', '#0f1721')}95`,
                color: getStyleValue('heading', 'color', '#00ffff'),
                fontFamily: getStyleValue('heading', 'font', ''),
                textAlign: getStyleValue('heading', 'alignment', 'left'),
              }}
              className={`w-full px-6 flex justify-between items-center py-[9px] xl:relative `}
            >
              <p
                className={`w-full mb-0 line-clamp-1 text-sm xl:text-[22px] ${getStyleValue(
                  'heading',
                  'font_format',
                  'font-bold',
                )}`}
              >
                {data?.name || ''}
              </p>
            </div>
          </div>
          <div className="">
            <div className=" grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[20px]">
              {!isEmpty(dataList) &&
                dataList.map((item, index) => (
                  <div key={index} className="pt-[8px] md:pt-4 lg:pt-8 xl:pt-10">
                    <VenueCard item={item} isEdit={isEdit} theme={theme} />
                  </div>
                ))}
              {isEmpty(dataList) && venueListLoading
                ? [1, 2, 3, 4, 5].map((idx) => (
                    <div
                      key={idx}
                      className="pt-[8px] md:pt-4 lg:pt-8  xl:pt-10 w-[157px]  md:w-[239px] xl:w-[260px] 2xl:w-[333px] "
                    >
                      <LoaderTile aspectRatio={'1.43537415'} />
                    </div>
                  ))
                : null}
            </div>
            {isEmpty(dataList) && !venueListLoading && (
              <div className="flex justify-center align-middle max-h-full">
                <p style={{ color: theme.c4 }} className="text-xl md:text-3xl">
                  {'no venue'}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(VenuePageComponent)
