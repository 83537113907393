import React from 'react'

const Forward2 = ({
  color = '#323232',
  buttonStyles = { width: '14.536px', height: '10.081px' },
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 14.536 10.081">
      <path
        data-name="Path 1042"
        d="M6.443 15.627A.888.888 0 0 1 5 14.933V7.969a.888.888 0 0 1 1.443-.694l4.357 3.482a.889.889 0 0 1 0 1.388z"
        transform="translate(-4.35 -6.411)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
      />
      <path
        data-name="Path 1043"
        d="M14.443 15.627A.888.888 0 0 1 13 14.933V7.969a.888.888 0 0 1 1.443-.694l4.357 3.482a.889.889 0 0 1 0 1.388z"
        transform="translate(-5.243 -6.411)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
      />
    </svg>
  )
}

export default Forward2
