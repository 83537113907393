import React from 'react'

const Forward3 = ({
  color = '#323232',
  buttonStyles = { width: '18.117px', height: '18.117px' },
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={buttonStyles} viewBox="0 0 18.117 18.117">
      <path
        d="M37.059 46.117a9.059 9.059 0 1 1 9.059-9.059 9.059 9.059 0 0 1-9.059 9.059zm0-17.392a8.334 8.334 0 1 0 8.334 8.334 8.334 8.334 0 0 0-8.334-8.334zm.563 8.635-4.348 2.9a.362.362 0 0 1-.563-.3v-5.8a.362.362 0 0 1 .563-.3l4.348 2.9a.362.362 0 0 1 0 .6zm-4.187-2.523v4.443l3.333-2.222zm9.26 2.523-4.348 2.9a.362.362 0 0 1-.563-.3v-5.8a.362.362 0 0 1 .563-.3l4.348 2.9a.362.362 0 0 1 0 .6zm-4.187-2.523v4.443l3.333-2.222z"
        transform="translate(-28 -28)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default Forward3
