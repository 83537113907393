import React, { useContext } from 'react'
import { BsEye } from 'react-icons/bs'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'
import { getImage, viewsFormatter } from '../../utils/helper'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import ArtistPlaceholder from '../../assets/artist-placeholder.jpg'
import CustomImage from '../../components/customImage/customImage'
import { AppContext } from '../../context/app'
import { withTheme } from 'theming'

const ArtistCard = ({ item, theme }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { accountObj, isCustomTemplate } = useContext(AppContext)
  const handleRedirect = (link) => {
    window.open(link)
  }

  const handleDetail = (item) => {
    navigate(`/artist/detail/${item._id}`)
  }
  return (
    <div
      style={{ borderColor: isCustomTemplate ? theme.c4 : '' }}
      className="shadow w-full border relative  cursor-pointer bg-black flex flex-col justify-center"
      onClick={() => handleDetail(item)}
    >
      <CustomImage
        imageUrl={getImage(accountObj?.cf_domain_name, item.poster)}
        imgClass="w-full object-contain"
        loaderClass={'h-64'}
        imgAlt={item.name}
        errorPlaceholder={ArtistPlaceholder}
      />
      <div
        style={{ color: isCustomTemplate ? theme.c7 : '#fff' }}
        className=" bg-gradient-to-r from-[#d20c83]/75 to-[#0dedf5]/75 bg-opacity-1 w-full absolute bottom-0"
      >
        <p className="w-full flex justify-center text-md font-semibold mt-1 md:mt-2 cursor-pointer px-4 line-clamp-1">
          {item.name}
        </p>
        <div className="flex justify-between p-2 align-bottom">
          <div className="flex items-center">
            {item.views_count !== 0 && <BsEye />}
            {item.views_count !== 0 && (
              <p className="text-xs md:text-sm px-1">
                {viewsFormatter(item.views_count)} {t('views')}
              </p>
            )}
          </div>
          <div className="flex text-xs md:text-sm justify-end space-x-3">
            {item.social_profiles && item.social_profiles?.facebook && (
              <FaFacebookF
                className="cursor-pointer"
                onClick={() => handleRedirect(item.social_profiles?.facebook)}
              />
            )}
            {item.social_profiles && item.social_profiles?.twitter && (
              <FaTwitter
                className="cursor-pointer"
                onClick={() => handleRedirect(item.social_profiles?.twitter)}
              />
            )}
            {item.social_profiles && item.social_profiles?.instagram && (
              <FaInstagram
                className="cursor-pointer"
                onClick={() => handleRedirect(item.social_profiles?.instagram)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(ArtistCard)
